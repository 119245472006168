const { format } = require('date-fns')
/**
 * Validate URL
 * @param {*} str
 */
const validURL = str => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str)
}

/**
 * Get url path
 * @param {*} url
 */
const getUrl = url => {
  try {
    var parser = new URL(url)
    const pathname = parser.pathname
    if (['/front-page/', '/home/', '/startsida/'].includes(pathname)) return '/'
    return pathname
  } catch (error) {
    return url
  }
}

/**
 * Get Redirect url
 * @param {*} attributes
 */
const getRedirectUrl = ({ pageRedirect }) => {
  if (pageRedirect && pageRedirect.url) return pageRedirect.url
  return null
}

/**
 * Is string ISO date
 * @param {*} str
 */
const isIsoDate = str => {
  var pattern = new RegExp(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/, 'i')
  return !!pattern.test(str)
}
/**
 * Get date compatible with Gravity Forms
 * @param {*} str
 */
const getGFCompatibleDate = str => {
  const date = new Date(str)
  return format(date, 'yyyy-MM-dd')
}

const PATH_PREFIX = process.env.GATSBY_PATH_PREFIX
  ? process.env.GATSBY_PATH_PREFIX
  : `/`
const SITE_URL = process.env.GATSBY_SITE_URL ? process.env.GATSBY_SITE_URL : `/`
const API_URL = process.env.GATSBY_API_URL
  ? process.env.GATSBY_API_URL
  : `http://localhost:8888/.netlify/functions`
const BASE_URL = process.env.GATSBY_BASE_URL ? process.env.GATSBY_BASE_URL : ``
const FORM_TOKEN = process.env.GATSBY_FORM_TOKEN
  ? process.env.GATSBY_FORM_TOKEN
  : ``
const CMS_URL = process.env.GATSBY_CMS_URL ? process.env.GATSBY_CMS_URL : ``
const CMS_AUTH = process.env.GATSBY_CMS_AUTH ? process.env.GATSBY_CMS_AUTH : ``
const GTM_ID = process.env.GATSBY_GTM_ID
  ? process.env.GATSBY_GTM_ID
  : 'GTM-WTPFBRT'
const GTM_ID_SECOND = process.env.GATSBY_GTM_ID_SECOND
  ? process.env.GATSBY_GTM_ID_SECOND
  : null
const CURRENT_LANG = process.env.GATSBY_CURRENT_LANG
  ? process.env.GATSBY_CURRENT_LANG
  : 'sv'
const TESTFREAKS_CLIENT_ID = process.env.GATSBY_TESTFREAKS_CLIENT_ID
  ? process.env.GATSBY_TESTFREAKS_CLIENT_ID
  : null
const GAMIFIERA_MERCHENT_ID = process.env.GATSBY_GAMIFIERA_MERCHENT_ID
  ? process.env.GATSBY_GAMIFIERA_MERCHENT_ID
  : null

/**
 * WH Data
 */
const WH_GRAPH_URL = process.env.WH_GRAPH_URL
  ? process.env.WH_GRAPH_URL
  : 'https://magento-stage.newbody.se/whgraphql'
const WH_MARKET = process.env.WH_MARKET ? process.env.WH_MARKET : 'SWEDEN'
const WH_LOCALE = process.env.WH_LOCALE ? process.env.WH_LOCALE : 'SV_SE'
const WH_PRODUCTION = process.env.WH_PRODUCTION
  ? process.env.WH_PRODUCTION
  : false
const API_ENVIRONMENT = process.env.API_ENVIRONMENT
  ? process.env.API_ENVIRONMENT
  : 'staging'
const WH_TICKET_URL = process.env.GATSBY_WH_TICKET_URL
  ? process.env.GATSBY_WH_TICKET_URL
  : 'https://magento-stage.newbody.se/whrma/generalreturns/ticket/id'
const RMA_TICKET_URL = process.env.GATSBY_RMA_TICKET_URL
  ? process.env.GATSBY_RMA_TICKET_URL
  : 'https://staging--newbodyfamily-portal.netlify.app/returns/ticket?token={{id}}'

/**
 * NB Shop
 */
const NB_SHOP_URL =
  process.env.NB_SHOP_URL ||
  process.env.GATSBY_NB_SHOP_URL ||
  'https://newbody-shop.netlify.app'

const POST_NUMBER_SERVICE_TOKEN = process.env.POST_NUMBER_SERVICE_TOKEN
  ? process.env.POST_NUMBER_SERVICE_TOKEN
  : false

const API_GATEWAY_TOKEN = process.env.API_GATEWAY_TOKEN
  ? process.env.API_GATEWAY_TOKEN
  : ''
const RAKET_API_GATEWAY_TOKEN = process.env.RAKET_API_GATEWAY_TOKEN
  ? process.env.RAKET_API_GATEWAY_TOKEN
  : ''

const API_ENDPOINTS = {
  MAGENTO: 'MAGENTO',
  COMMON: 'COMMON',
  INVENTORY: 'INVENTORY',
  POST_NUMBER_SERVICE: 'POST_NUMBER_SERVICE',
  APSIS: 'APSIS',
  SERVICES: 'SERVICES',
}

/**
 * Get API endpoint
 * @param {*} api
 * @returns
 */
const getApiEndpoint = api => {
  const endpoints = {
    // Switched from api.newbody to obnoxious 21-09-20 to smoke out POST problems
    staging: {
      MAGENTO: 'https://magento-stage.newbody.se/whgraphql',
      COMMON: 'https://api.newbodyfamily.com/test/e-commerce/v1',
      INVENTORY: 'https://api.newbodyfamily.com/test/inventory',
      POST_NUMBER_SERVICE: 'https://api.newbodyfamily.com/postnummerservice/v2',
      APSIS:
        'https://gpxunfx4lh.execute-api.eu-north-1.amazonaws.com/test/apsis/v1',
      SERVICES:
        'https://gpxunfx4lh.execute-api.eu-north-1.amazonaws.com/test/services/v1',
    },
    production: {
      MAGENTO: 'https://www.newbody.se/whgraphql',
      COMMON: 'https://api.newbodyfamily.com/prod/e-commerce/v1',
      INVENTORY: 'https://api.newbodyfamily.com/prod/inventory',
      POST_NUMBER_SERVICE: 'https://api.newbodyfamily.com/postnummerservice/v2',
      APSIS:
        'https://gpxunfx4lh.execute-api.eu-north-1.amazonaws.com/prod/apsis/v1',
      SERVICES:
        'https://gpxunfx4lh.execute-api.eu-north-1.amazonaws.com/prod/services/v1',
    },
  }
  const env = API_ENVIRONMENT
  return endpoints[env][api]
}

/**
 * Error
 */
const SENTRY_DNS = process.env.SENTRY_DNS ? process.env.SENTRY_DNS : ''

module.exports = {
  getRedirectUrl,
  getUrl,
  validURL,
  PATH_PREFIX,
  WH_GRAPH_URL,
  WH_MARKET,
  WH_LOCALE,
  WH_PRODUCTION,
  API_URL,
  BASE_URL,
  WH_TICKET_URL,
  RMA_TICKET_URL,
  SITE_URL,
  FORM_TOKEN,
  SENTRY_DNS,
  CMS_URL,
  CMS_AUTH,
  GTM_ID,
  GTM_ID_SECOND,
  CURRENT_LANG,
  POST_NUMBER_SERVICE_TOKEN,
  API_ENDPOINTS,
  NB_SHOP_URL,
  isIsoDate,
  getGFCompatibleDate,
  getApiEndpoint,
  API_GATEWAY_TOKEN,
  RAKET_API_GATEWAY_TOKEN,
  API_ENVIRONMENT,
  TESTFREAKS_CLIENT_ID,
  GAMIFIERA_MERCHENT_ID,
}
